import React from "react";
import Layout from '../components/layout';
import PrimaryButton from '../components/button';

const PricingPage = props => (
  <Layout pageTitle="Docs">
    <main className={"tc pv6"}>
      <h1 className={"f1"}>
        404
      </h1>
      <h4 className={"f4"}>
        Page not found
      </h4>
      <PrimaryButton text="Return Home" link="/" extraClasses=" " />
    </main>
  </Layout>
);

export default PricingPage;
